<script setup>
const props = defineProps({
  title: {
    type: String,
    default: 'Althea',
    required: false
  }
})
</script>

<template>
  <header class="flex items-center px-10 py-4 bg-base-200">
    <section class="grow">
      <router-link to="/" class="text-4xl link link-hover">{{props.title}}</router-link>
    </section>
    <section>
      <router-link to="/logout" class="btn btn-square btn-ghost py-3 px-10">Logout</router-link>
    </section>
  </header>
</template>