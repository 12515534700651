<script setup>
import { RouterView } from 'vue-router'
import TheHeader from '@/components/core/TheHeader.vue'
import { useUserStore } from '@/stores/user.js'
const user = useUserStore()

</script>

<template>
  <div class="min-h-screen pb-10">
    <TheHeader v-if="user.isAuthenticated()" />
    <div class="grid grid-cols-12 grid-rows-[min-content] gap-y-12 lg:gap-x-12 px-10 py-6">
      <RouterView />
    </div>
  </div>
</template>

<style scoped>

</style>
