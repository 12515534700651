import axios from 'axios'

const UNAUTHENTICATED_HEADERS = { headers: { 'Content-Type': 'application/json' } }

export function useUnauthenticatedPost(path, data) {
  const result = axios.post(import.meta.env.VITE_ALTHEA_BASE_URL + path, data, UNAUTHENTICATED_HEADERS )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
  return result
}

export function useUnauthenticatedPatch(path, data) {
  const result = axios.patch(import.meta.env.VITE_ALTHEA_BASE_URL + path, data, UNAUTHENTICATED_HEADERS )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
  return result
}

export function useAuthenticatedPost(path, data) {
  const token = localStorage.getItem('token')
  const url = import.meta.env.VITE_ALTHEA_BASE_URL + path
  const result = axios.post(url, data, { headers: { Authorization: token, 'Content-Type': 'application/json'} })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        console.log("Timed out, bruh") //not totally accurate...just unauthorized
      } else{
        return error.response
      }
    });
  return result
}

export function useAuthenticatedPut(path, data) {
  const token = localStorage.getItem('token')
  const url = import.meta.env.VITE_ALTHEA_BASE_URL + path
  const result = axios.put(url, data, { headers: { Authorization: token, 'Content-Type': 'application/json' } })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        console.log("Timed out, bruh") //not totally accurate...just unauthorized
      } else {
        return error.response
      }
    })
  return result
}

export function useAuthenticatedGet(path, data) {
  console.log('getting', path)
  const token = localStorage.getItem('token')
  const url = data ? import.meta.env.VITE_ALTHEA_BASE_URL + path + "/" + data + ".json" : import.meta.env.VITE_ALTHEA_BASE_URL + path + ".json"
  console.log('!!!!')
  const result = axios.get(url, { headers: { Authorization: token, 'Content-Type': 'application/json'} })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        console.log("Timed out, bruh") //not totally accurate...just unautharized
      } else{
        return error.response
      }
    });
  return result
}


export function useAuthenticatedDelete(path, data) {
  const token = localStorage.getItem('token')
  const result = axios.delete(import.meta.env.VITE_ALTHEA_BASE_URL + path, { headers: { Authorization: token } }, data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        console.log("Timed out, bruh") //not totally accurate...just unautharized
      } else{
        return error.response
      }
    });
  return result
}